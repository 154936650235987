$NvGreen: #76b900;
$NvGreen2: #4f7c00;
$NvGreen3: #004e49;
$ColorWarn: #f38f1d;
$ColorCancel: #eccd08;
$ColorError: #9c0000;
$ColorSuccess: $NvGreen;
$ColorNeutral: #c9c9c9;
$ColorPending: #fb7200;
$NvFont: "DINWebPro-Light";
$pageWidth: 96vw;
$contentPaddingTop: 1.75em;
$contentPaddingSides: 1.25em;
$boxShadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
$requiredColor: #d40000;
$requiredColorHover: lighten($requiredColor, 20);

@font-face {
  font-family: DINWebPro-Light;
  src: url("../fonts/DINWebPro-Light.woff") format("woff");
}
.wordstyling {
  word-break: break-all;
}
.toggle-container{
  margin-left: 10px;
  width:70px;
  background-color: #c4c4c4;
  cursor: pointer;
  user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: 31px;
  position: relative;
}
.disabled{
  background-color: #707070 !important;
  left: 2px !important;
}
.dialog-button{
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
  cursor: pointer;
  background-color: #76b900;
  color: white;
  padding: 8px 12px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 38px;
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 34px;
  transition: all 0.3s ease;
  height: 88%;
}
.rowcssstyling {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.Mui-disabled {
  padding: 0px !important;
}
.columncssstyling {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  flex-basis: 33.333333%;
  flex: 1 0 33%;
  flex-grow: 0 !important;

  // flex-basis: 100%;
  // flex: 1;
}
.mainwrapper {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-left: -1em;
  margin-right: -1em;
  background-color: $NvGreen;
  border-bottom: 3px solid $NvGreen2;
  box-shadow: $boxShadow;

  > .flex:first-child {
    margin-bottom: 1em;
  }

  label.block {
    color: #fff;
  }
}
.color-nvgreen {
  color: $NvGreen !important;
}
.bg-color-nvgreen {
  background-color: $NvGreen !important;
}
.color-warn {
  color: $ColorWarn !important;
}
.bg-color-warn {
  background-color: $ColorWarn !important;
}
.color-error {
  color: $ColorError !important;
}
.color-orange {
  color: $ColorPending !important;
}
.bg-color-error {
  background-color: $ColorError !important;
}
.color-neutral {
  color: $ColorNeutral !important;
}
.bg-color-neutral {
  background-color: $ColorNeutral !important;
}

html,
body {
  background-color: #fff;
  margin: 0;
  padding: 0 0 2em 0;
  font-family: $NvFont;
  max-width: 100%;
}
hr,
.hr {
  border: none;
  border-top: 1px solid $ColorNeutral;
}

input.input {
  border: 1px solid $ColorNeutral;
  border-radius: 4px;
  padding: 0.5em 0.75em;
}
input[type="number"].input {
  display: flex;
  align-content: center;
}
label.required {
  &:before {
    content: "* ";
    color: $requiredColor;
  }
}
.block {
  display: block;
}
.iblock {
  display: inline-block;
}
.text-right {
  text-align: right;
}
.flex {
  display: flex;
}
.flex-fill {
  flex-grow: 1;
}

.no-top-margin {
  margin-top: 0 !important;
}
.margin-width {
  margin-left: 1em;
  margin-right: 1em;
}

.margin-left {
  margin-left: 26px;
  margin-top: 4px;
}
.margin-leftInput {
  margin-left: 50px;
  margin-top: 4px;
}
.margin-leftRWAMapping {
  margin-left: 20px;
  margin-top: 4px;
}
.margin-leftInputNGC {
    margin-left: 75px;
    margin-top: 4px;
}
.margin-left2 {
  margin-left: 1px;
  margin-top: 4px;
}

.selectWidth {
  width: 160px;
  height: 25px;
  margin-left: 8px;
}
.selectWidthSelect {
  width: 160px;
  height: 25px;
  /*margin-left: 36px;*/
  position:fixed;
  left:55%;
}
.selectWidth2 {
  width: 160px;
  height: 25px;
  margin-left: 20px;
}

.margin-height {
  margin-top: 1em;
  margin-bottom: 1em;
}

.divAddBtn {
  margin-right: 1em;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 4em);
}

.content {
  background-color: white;
  max-width: 100%;
  margin-left: 1em;
  margin-right: 1em;
  padding-top: $contentPaddingTop;
  box-sizing: border-box;
  flex-grow: 1;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 1.5em 0px 0.25em 0;
    color: $NvGreen;

    // color: white;
    // background-color: $NvGreen3;
    // margin-left: -20px;
    // padding: 0.5em;
    // display: inline-block;
  }
  h1,
  h2,
  h3 {
    font-weight: 100;
  }
  h4,
  h5,
  h6 {
    font-weight: 900;
  }

  input {
    padding: 0.25em;
    &:read-only {
      background-color: darken(#fff, 20%);
    }
  }
}

.modalDivHeader {
  background-color: #76b900;
  height: 35px;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
}

.modalLabel {
  margin-top: -20px !important;
  color: #ffffff;
  font-weight: bold;
}

.whiteText {
  color: #ffffff;
}

header {
  background-color: black;
  height: 75px;

  .header-content {
    width: $pageWidth;
    //width: 1024px;
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    background-repeat: no-repeat;
    background-position-x: right;
    margin-top: -10px;
  }

  .header-top {
    background-color: white;
  }

  .header-text {
    color: $NvGreen;
    font-family: $NvFont;
    flex-grow: 1;
    min-width: 250px;
    font-size: 18px;
    font-weight: 100;
    margin-left: -8%;
    margin-top: 11.5%;
  }

  .header-logo {
    height: 85px;
    text-align: right;
    background: #ffffff;
    margin-left: -2.5%;
  }

  .header-badge {
    color: $NvGreen;
  }

  nav {
    position: absolute;
    margin-top: -1.2em;

    > a {
      color: #fff;
      text-decoration: none;

      &:hover {
        color: $NvGreen;
      }
    }
  }
}

.detail-actions {
  //text-align: right;
  padding-top: 1em;

  // > button {
  //   text-align: center;
  //   padding: 0.25em 0.5em;
  // }
}

.matches {
  .match-row:nth-child(2n + 2) {
    background-color: #e2e2e2;
  }
}
.match-row {
  display: flex;
  align-items: center;

  > div {
    margin: 0.25em 0.5em;
    width: 100px;
  }
  > div:nth-child(1) {
    width: 190px;
  }
  > div:nth-child(3) {
    //width: 200px;
    flex-grow: 1;
    word-wrap: break-word;
  }
  > div:nth-child(4) {
    width: 200px;
  }
  > div:nth-child(5) {
    width: 150px;
  }
  > div:nth-child(6) {
    width: 100px;
  }
  > div:nth-child(7) {
    width: 50px;
  }
  > div:nth-child(8) {
    //flex-grow: 1;
    text-align: center;
  }
}

.row {
  display: flex;
  justify-content: stretch;
}

.cols-2 {
  margin-top: 2em;
  display: flex;

  > div {
    //width: 50%;
    margin-left: 2em;
  }
  h4 {
    margin: 0;
  }
}
.cols-input {
  display: flex;
  margin: 1em 0;

  label {
    display: flex;
  }

  > div {
    width: 50%;
    > input {
      width: 200px;
      box-sizing: border-box;
    }
  }
  > div:nth-child(2n + 1) {
    text-align: right;
    padding-right: 1em;
  }
}

.matches-filter {
  > * {
    margin: 1em 0.25em;
  }
  > input[type="number"] {
    width: 3em;
    padding: 0.25em;
  }
}
.align-bottom {
  align-self: flex-end;
}

.align-center {
  display: flex;
  align-items: center;
}

.pull-right {
  float: right;
  clear: both;
}

.orch-status {
  margin-bottom: 1em;
  .s-field {
    > span:first-child {
      width: 200px !important;
    }
    > span:last-child {
      flex-grow: 1;
    }
  }
  .s-field.error {
    > label {
      background-color: #9c0000;
    }
  }
}

#btnClose {
  position: absolute;
  color: black;
  top: 10px;
  right: 3px;
}

#btnAdd {
  float: right;
  height: 40px;
  margin-top: 15px;
}

.lblNVGreen {
  color: $NvGreen;
  min-width: 7em;
  display: inline-block;
  align-self: stretch;
}

.toast-margin {
  margin-left: -15% !important;
  word-break: break-all;
}

.s-field {
  background-color: #ccc;
  font-size: 1.1em;
  margin-right: 0.5em;
  display: flex;
  align-items: center;

  > label {
    background-color: $NvGreen;
    color: #fff;
    padding: 0.5em;
    min-width: 7em;
    display: inline-block;
    align-self: stretch;
  }

  > span {
    padding: 0.5em;
    display: inline-block;
    min-width: 12em;
    word-break: break-word;
    align-self: stretch;
  }
}

.btn + .btn {
  margin-left: 0.2em;
}
.btn.inline {
  padding: 0.4em 0.6em;
  margin: 0 0.5em;
  font-size: 1em;
}
.btn {
  border: none;
  box-shadow: $boxShadow;
  background-color: $NvGreen2;
  transition: all 0.25s ease-in;
  color: #fff;
  padding: 0.75em 1.25em;
  font-size: 1.1em;
  border-radius: 0.2em;
  &:hover {
    cursor: pointer;
    background-color: lighten($NvGreen2, 4%);
    box-shadow: none;
  }
}
.btn[disabled] {
  background-color: $ColorNeutral;
  box-shadow: none;
  &:hover {
    cursor: default;
  }
}
.btn.primary {
  background-color: $NvGreen;
  &:hover {
    background-color: lighten($NvGreen, 4%);
    color: #000;
  }
}
.btn.small {
  box-shadow: none;
  width: 100%;
  padding: 0.25em 0;
  background-color: #e2e2e2;
  background-color: darken(#e2e2e2, 10%);
  color: #000;
  font-size: 0.9em;
  font-variant-caps: all-small-caps;
  &:hover {
    background-color: darken(#e2e2e2, 5%);
  }
}
.detail-back {
  flex-grow: 1;
  display: flex;
  align-self: center;
  justify-content: flex-end;
}

.landing {
  padding-top: 1.5em;

  > p {
    margin: 1em 2em 2em 2em;
    text-align: center;
    font-size: 1.2em;
  }

  > div {
    display: flex;
    justify-content: center;

    > a {
      background-color: $NvGreen;
      color: #fff;
      box-shadow: 0.25em 0.25em 1.5em #c4c3c3;
      margin: 0 0.25em;
      height: 200px;
      min-width: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 2em;
      text-decoration: none;
      transition: all 0.25s ease-in;
      &:hover {
        background-color: lighten($NvGreen, 4%);
        color: #000;
        cursor: pointer;
        box-shadow: 0.25em 0.25em 1.5em #adabab;
      }
    }
  }
}
.record-count {
  margin-left: 1.5em;
}

.box {
  width: 100px;
  height: 100px;
  background: #ff1c68;
  transform-origin: 50% 50%;
}
.rdrStaticRange {
  color: black !important;
  font-weight: 500 !important;
  border: 1px solid #eff2f7 !important;
}
.datepickerrangeto input[type="text"] {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}
.datepickerrangefrom input[type="text"] {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0;
}
.rdrDefinedRangesWrapper {
  border-right: 0 !important;
}
.react-datepicker-wrapper {
  width: 75%;
}
.labelMargin {
  margin-top: 1.4em;
  font-weight: bold;
  margin-left: 4em;
}
.datepickerrangeto {
  margin-top: 1em;
}
.datepickerrangefrom {
  margin-left: 1em;
  margin-top: 1em;
}
.rdrStaticRanges {
  flex-direction: row !important;
}
.datestaticrange {
  margin-left: 0em;
  margin-top: 1em;
}
.dashboard {
  display: flex;
  margin-bottom: 0;
  margin-top: 15px;

  .dashboard-text {
    flex-grow: 1;
    text-align: right;
    align-self: flex-end;
  }

  > button.success {
    border-color: $ColorSuccess;

    .badge {
      background-color: $ColorSuccess;
      color: #fff;
    }
  }

  > button.success-alt {
    border-color: $NvGreen2;

    .badge {
      background-color: $NvGreen2;
      color: #fff;
    }
  }
  > button.cancel-alt {
    border-color: $ColorCancel;

    .badge {
      background-color: $ColorCancel;
      color: #fff;
    }
  }
  > button.warn {
    border-color: $ColorWarn;

    .badge {
      background-color: $ColorWarn;
      color: #fff;
    }
  }

  > button.error {
    border-color: $ColorError;

    .badge {
      background-color: $ColorError;
      color: #fff;
    }
  }

  > button {
    transition: all 0.25s ease-in;
    margin-bottom: 2em;
    display: flex;
    border: 1px solid $ColorNeutral;
    border-radius: 3px;
    background-color: #fff;
    padding: 0;
    align-items: stretch;
    box-shadow: $boxShadow;

    &:hover {
      cursor: pointer;
      background-color: darken(#fff, 5);
      box-shadow: none;
    }

    .badge {
      width: 50px;
      padding: 0.75em 0.2em;
      background-color: $ColorNeutral;
      color: #333;
    }

    .text {
      padding: 0.75em;
    }

    + button {
      margin-left: 1em;
    }
  }
}

.loading-full {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;

  .loading-text {
    color: #333;
    font-family: $NvFont;
    font-size: 2em;
    padding-left: 0.5em;
  }
}

.react-datepicker__input-container {
  > input {
    width: 100%;
  }
  width: 100%;
}
.react-datepicker__close-icon {
  height: 15px;
  &::after {
    background-color: $ColorNeutral;
  }
  &:hover {
    &::after {
      background-color: darken($ColorNeutral, 5);
      color: #333;
    }
  }
}
.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  position: static;
}

.ReactTable {
  .rt-th {
    overflow: visible;
  }
  .rt-table {
    //overflow: visible;
    .rt-td {
      overflow: hidden;
    }
  }
  .btn {
    box-shadow: none;
    width: 90px;
    font-family: $NvFont;
    padding: 0.5em 0;
  }
  .-pagination .-btn:not([disabled]) {
    background-color: $NvGreen;
    color: #fff;
  }
  .-pagination .-btn:not([disabled]):hover {
    background: lighten($NvGreen, 4%);
    color: #fff;
  }

  .rt-noData {
    display: block;
    position: absolute;
    left: 50%;
    top: 190px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: #000;
    border: 2px solid $NvGreen;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
}

.reg-list {
  width: 100%;
  margin-top: 1em;

  .filter-control {
    padding-top: 1em;
    display: flex;
    > input,
    select {
      margin-right: 0.5em;
      width: 100%;
      padding: 0;
      padding: 0.15em;
    }
  }
  .filter-control-stub {
    height: 23px;
    padding-top: 1em;
  }

  > div:nth-child(1) {
    background-color: $NvGreen;
    color: #fff;
  }
  > div:nth-child(2n + 2) {
    background-color: #e2e2e2;
  }

  > div {
    display: flex;
    align-items: center;
    border: 1px solid darken(#e2e2e2, 5%);
    border-top: none;

    > div {
      margin: 0.15em;
      word-break: break-all;
    }
    > div:nth-child(1) {
      //id
      width: 30px;
    }
    > div:nth-child(2) {
      //entitlement
      width: 90px;
    }
    > div:nth-child(3) {
      //type
      width: 110px;
    }
    > div:nth-child(4) {
      //family
      width: 90px;
    }
    > div:nth-child(5) {
      //email
      width: 150px;
      word-wrap: break-word;
    }
    > div:nth-child(6) {
      //account
      flex-grow: 1;
    }
    > div:nth-child(7) {
      //status
      width: 90px;
    }
    > div:nth-child(8) {
      //date
      width: 140px;
      > .filter-control {
        flex-direction: column;
        > input[type="date"] {
          width: auto;
        }
      }
    }
    > div:nth-child(9) {
      //action
      width: 70px;
      margin: 0em;
      text-align: center;
      > a {
        height: 100%;
        width: 100%;
        button {
          padding: 0.25em;
          height: 100%;
          width: 100%;
          box-shadow: none;
          background-color: darken(#e2e2e2, 10%);
          color: #000;
          border: 1px solid darken(#e2e2e2, 7%);
          &:hover {
            cursor: pointer;
            background-color: darken(#e2e2e2, 5%);
          }
        }
      }
    }
  }
}

.content {
  .section-highlight {
    padding-top: 1em;
    padding-bottom: 1em;
    margin-left: -1em;
    margin-right: -1em;
    background-color: $NvGreen;
    border-bottom: 3px solid $NvGreen2;
    box-shadow: $boxShadow;

    > .flex:first-child {
      margin-bottom: 1em;
    }

    label.block {
      color: #fff;
    }
  }
  .flexwrapstyle {
    flex-wrap: wrap;
  }
  .flexwrapsize {
    flex-basis: 33%;
  }

  .details-page-header {
    color: $NvGreen;
    margin-top: 0;

    > small {
      color: #333;
      font-size: 0.6em;
      padding-left: 2em;
    }
  }

  .details-reg-header {
    color: #333;
    margin-top: 0.25em;
    font-size: 2em;

    > small {
      color: $NvGreen2;
      font-size: 0.6em;
      padding-right: 0.5em;
      font-style: italic;
    }
  }

  .details-section-header {
    color: $NvGreen2;
    margin-top: 1.25em;
    margin-bottom: 0.5em;
    font-size: 1.5em;
    display: inline-block;
  }

  label.block {
    width: 150px;
    font-size: 1.2em;
    text-align: right;
    padding-right: 1em;
    margin-bottom: 0.5em;
  }

  .sub-status {
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 1em;

    > div:last-child {
      border-bottom: none;
    }

    > div:not(.color-neutral) {
      > div:first-child {
        font-weight: 900;
      }
    }

    > div {
      display: flex;
      margin-bottom: 0.5em;
      padding-bottom: 0.5em;
      border-bottom: 1px solid $ColorNeutral;

      > div:first-child {
        width: 100px;
        min-width: 100px;
      }

      > div:nth-child(2) {
        width: 100px;
        min-width: 100px;
      }

      > div:nth-child(3) {
        flex-grow: 1;
        word-wrap: break-word;
        min-width: 100px;
      }
    }
  }

  .company-details {
    margin-left: 1em;
    margin-right: 1em;
    margin-bottom: 1em;

    label {
      display: flex;
      position: relative;
      align-items: center;
      height: 2.3em;

      input[type="text"] {
        flex: none;
        position: absolute;
        left: 60px;
        border: 1px solid $ColorNeutral;
        border-radius: 4px;
        padding: 0.5em 0.75em;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  $pageWidth: 100vw;

  .dashboard {
    flex-direction: column;
    margin-bottom: 0;

    > button {
      margin-bottom: 0;
    }

    > button + button {
      margin-left: 0em;
    }

    > button > .text {
      flex-grow: 1;
    }

    > .dashboard-text {
      align-self: center;
      margin-top: 2em;
    }
  }

  header {
    .header-content {
      background-size: cover;
      width: 100vw;
      padding-left: 1em;
    }
  }

  .content {
    .details-reg-header {
      margin-top: 0;

      small {
        display: block;
        margin-bottom: 0.5em;
      }
    }

    .details-section-header {
      color: #333;
      margin-top: 1em;
      margin-bottom: 1em;
      font-size: 1.5em;

      > small {
        color: $NvGreen2;
        font-size: 0.6em;
        padding-right: 0.5em;
        font-style: italic;
        display: block;
      }
    }

    .btn.inline.bg-color-nvgreen {
      margin: -1em 0em 1em 0;
    }
  }
}
