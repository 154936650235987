.rowClassName1 {
    background-color: #fff;
    border-radius: 10px!important;
    border-color: red;
    border-width: 16px!important;
    border-style: solid!important;
  }
  .ant-table-tbody{
    padding: 5px!important;
  }
  .ant-table-tbody > tr > td{
    padding: 8px 8px 8px 8px !important;
  }
  .ant-table{
    margin: 20px!important;
  }
  .auditButton{
margin-right: 10px!important;
  }