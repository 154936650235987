@font-face {
  font-family: DINWebPro-Light;
  src: url(/static/media/DINWebPro-Light.cbaf288b.woff) format("woff"); }

.wordstyling {
  word-break: break-all; }

.toggle-container {
  margin-left: 10px;
  width: 70px;
  background-color: #c4c4c4;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border-radius: 3px;
  padding: 2px;
  height: 31px;
  position: relative; }

.disabled {
  background-color: #707070 !important;
  left: 2px !important; }

.dialog-button {
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
  cursor: pointer;
  background-color: #76b900;
  color: white;
  padding: 8px 12px;
  border-radius: 18px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  min-width: 46px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  width: 38px;
  min-width: unset;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 34px;
  transition: all 0.3s ease;
  height: 88%; }

.rowcssstyling {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%; }

.Mui-disabled {
  padding: 0px !important; }

.columncssstyling {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  overflow: hidden;
  -webkit-flex-basis: 33.333333%;
          flex-basis: 33.333333%;
  -webkit-flex: 1 0 33%;
          flex: 1 0 33%;
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important; }

.mainwrapper {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-left: -1em;
  margin-right: -1em;
  background-color: #76b900;
  border-bottom: 3px solid #4f7c00;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23); }
  .mainwrapper > .flex:first-child {
    margin-bottom: 1em; }
  .mainwrapper label.block {
    color: #fff; }

.color-nvgreen {
  color: #76b900 !important; }

.bg-color-nvgreen {
  background-color: #76b900 !important; }

.color-warn {
  color: #f38f1d !important; }

.bg-color-warn {
  background-color: #f38f1d !important; }

.color-error {
  color: #9c0000 !important; }

.color-orange {
  color: #fb7200 !important; }

.bg-color-error {
  background-color: #9c0000 !important; }

.color-neutral {
  color: #c9c9c9 !important; }

.bg-color-neutral {
  background-color: #c9c9c9 !important; }

html,
body {
  background-color: #fff;
  margin: 0;
  padding: 0 0 2em 0;
  font-family: "DINWebPro-Light";
  max-width: 100%; }

hr,
.hr {
  border: none;
  border-top: 1px solid #c9c9c9; }

input.input {
  border: 1px solid #c9c9c9;
  border-radius: 4px;
  padding: 0.5em 0.75em; }

input[type="number"].input {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center; }

label.required:before {
  content: "* ";
  color: #d40000; }

.block {
  display: block; }

.iblock {
  display: inline-block; }

.text-right {
  text-align: right; }

.flex {
  display: -webkit-flex;
  display: flex; }

.flex-fill {
  -webkit-flex-grow: 1;
          flex-grow: 1; }

.no-top-margin {
  margin-top: 0 !important; }

.margin-width {
  margin-left: 1em;
  margin-right: 1em; }

.margin-left {
  margin-left: 26px;
  margin-top: 4px; }

.margin-leftInput {
  margin-left: 50px;
  margin-top: 4px; }

.margin-leftRWAMapping {
  margin-left: 20px;
  margin-top: 4px; }

.margin-leftInputNGC {
  margin-left: 75px;
  margin-top: 4px; }

.margin-left2 {
  margin-left: 1px;
  margin-top: 4px; }

.selectWidth {
  width: 160px;
  height: 25px;
  margin-left: 8px; }

.selectWidthSelect {
  width: 160px;
  height: 25px;
  /*margin-left: 36px;*/
  position: fixed;
  left: 55%; }

.selectWidth2 {
  width: 160px;
  height: 25px;
  margin-left: 20px; }

.margin-height {
  margin-top: 1em;
  margin-bottom: 1em; }

.divAddBtn {
  margin-right: 1em; }

.app {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-height: calc(100vh - 4em); }

.content {
  background-color: white;
  max-width: 100%;
  margin-left: 1em;
  margin-right: 1em;
  padding-top: 1.75em;
  box-sizing: border-box;
  -webkit-flex-grow: 1;
          flex-grow: 1; }
  .content h1,
  .content h2,
  .content h3,
  .content h4,
  .content h5,
  .content h6 {
    margin: 1.5em 0px 0.25em 0;
    color: #76b900; }
  .content h1,
  .content h2,
  .content h3 {
    font-weight: 100; }
  .content h4,
  .content h5,
  .content h6 {
    font-weight: 900; }
  .content input {
    padding: 0.25em; }
    .content input:-moz-read-only {
      background-color: #cccccc; }
    .content input:read-only {
      background-color: #cccccc; }

.modalDivHeader {
  background-color: #76b900;
  height: 35px;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px; }

.modalLabel {
  margin-top: -20px !important;
  color: #ffffff;
  font-weight: bold; }

.whiteText {
  color: #ffffff; }

header {
  background-color: black;
  height: 75px; }
  header .header-content {
    width: 96vw;
    max-width: 100%;
    margin: 0 auto;
    display: -webkit-flex;
    display: flex;
    background-repeat: no-repeat;
    background-position-x: right;
    margin-top: -10px; }
  header .header-top {
    background-color: white; }
  header .header-text {
    color: #76b900;
    font-family: "DINWebPro-Light";
    -webkit-flex-grow: 1;
            flex-grow: 1;
    min-width: 250px;
    font-size: 18px;
    font-weight: 100;
    margin-left: -8%;
    margin-top: 11.5%; }
  header .header-logo {
    height: 85px;
    text-align: right;
    background: #ffffff;
    margin-left: -2.5%; }
  header .header-badge {
    color: #76b900; }
  header nav {
    position: absolute;
    margin-top: -1.2em; }
    header nav > a {
      color: #fff;
      text-decoration: none; }
      header nav > a:hover {
        color: #76b900; }

.detail-actions {
  padding-top: 1em; }

.matches .match-row:nth-child(2n + 2) {
  background-color: #e2e2e2; }

.match-row {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .match-row > div {
    margin: 0.25em 0.5em;
    width: 100px; }
  .match-row > div:nth-child(1) {
    width: 190px; }
  .match-row > div:nth-child(3) {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    word-wrap: break-word; }
  .match-row > div:nth-child(4) {
    width: 200px; }
  .match-row > div:nth-child(5) {
    width: 150px; }
  .match-row > div:nth-child(6) {
    width: 100px; }
  .match-row > div:nth-child(7) {
    width: 50px; }
  .match-row > div:nth-child(8) {
    text-align: center; }

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: stretch;
          justify-content: stretch; }

.cols-2 {
  margin-top: 2em;
  display: -webkit-flex;
  display: flex; }
  .cols-2 > div {
    margin-left: 2em; }
  .cols-2 h4 {
    margin: 0; }

.cols-input {
  display: -webkit-flex;
  display: flex;
  margin: 1em 0; }
  .cols-input label {
    display: -webkit-flex;
    display: flex; }
  .cols-input > div {
    width: 50%; }
    .cols-input > div > input {
      width: 200px;
      box-sizing: border-box; }
  .cols-input > div:nth-child(2n + 1) {
    text-align: right;
    padding-right: 1em; }

.matches-filter > * {
  margin: 1em 0.25em; }

.matches-filter > input[type="number"] {
  width: 3em;
  padding: 0.25em; }

.align-bottom {
  -webkit-align-self: flex-end;
          align-self: flex-end; }

.align-center {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.pull-right {
  float: right;
  clear: both; }

.orch-status {
  margin-bottom: 1em; }
  .orch-status .s-field > span:first-child {
    width: 200px !important; }
  .orch-status .s-field > span:last-child {
    -webkit-flex-grow: 1;
            flex-grow: 1; }
  .orch-status .s-field.error > label {
    background-color: #9c0000; }

#btnClose {
  position: absolute;
  color: black;
  top: 10px;
  right: 3px; }

#btnAdd {
  float: right;
  height: 40px;
  margin-top: 15px; }

.lblNVGreen {
  color: #76b900;
  min-width: 7em;
  display: inline-block;
  -webkit-align-self: stretch;
          align-self: stretch; }

.toast-margin {
  margin-left: -15% !important;
  word-break: break-all; }

.s-field {
  background-color: #ccc;
  font-size: 1.1em;
  margin-right: 0.5em;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .s-field > label {
    background-color: #76b900;
    color: #fff;
    padding: 0.5em;
    min-width: 7em;
    display: inline-block;
    -webkit-align-self: stretch;
            align-self: stretch; }
  .s-field > span {
    padding: 0.5em;
    display: inline-block;
    min-width: 12em;
    word-break: break-word;
    -webkit-align-self: stretch;
            align-self: stretch; }

.btn + .btn {
  margin-left: 0.2em; }

.btn.inline {
  padding: 0.4em 0.6em;
  margin: 0 0.5em;
  font-size: 1em; }

.btn {
  border: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
  background-color: #4f7c00;
  transition: all 0.25s ease-in;
  color: #fff;
  padding: 0.75em 1.25em;
  font-size: 1.1em;
  border-radius: 0.2em; }
  .btn:hover {
    cursor: pointer;
    background-color: #5c9000;
    box-shadow: none; }

.btn[disabled] {
  background-color: #c9c9c9;
  box-shadow: none; }
  .btn[disabled]:hover {
    cursor: default; }

.btn.primary {
  background-color: #76b900; }
  .btn.primary:hover {
    background-color: #83cd00;
    color: #000; }

.btn.small {
  box-shadow: none;
  width: 100%;
  padding: 0.25em 0;
  background-color: #e2e2e2;
  background-color: #c9c9c9;
  color: #000;
  font-size: 0.9em;
  -webkit-font-feature-settings: "smcp", "c2sc";
          font-feature-settings: "smcp", "c2sc";
  font-variant-caps: all-small-caps; }
  .btn.small:hover {
    background-color: #d5d5d5; }

.detail-back {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-self: center;
          align-self: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }

.landing {
  padding-top: 1.5em; }
  .landing > p {
    margin: 1em 2em 2em 2em;
    text-align: center;
    font-size: 1.2em; }
  .landing > div {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
    .landing > div > a {
      background-color: #76b900;
      color: #fff;
      box-shadow: 0.25em 0.25em 1.5em #c4c3c3;
      margin: 0 0.25em;
      height: 200px;
      min-width: 300px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      font-size: 2em;
      text-decoration: none;
      transition: all 0.25s ease-in; }
      .landing > div > a:hover {
        background-color: #83cd00;
        color: #000;
        cursor: pointer;
        box-shadow: 0.25em 0.25em 1.5em #adabab; }

.record-count {
  margin-left: 1.5em; }

.box {
  width: 100px;
  height: 100px;
  background: #ff1c68;
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%; }

.rdrStaticRange {
  color: black !important;
  font-weight: 500 !important;
  border: 1px solid #eff2f7 !important; }

.datepickerrangeto input[type="text"] {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0; }

.datepickerrangefrom input[type="text"] {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline-width: 0; }

.rdrDefinedRangesWrapper {
  border-right: 0 !important; }

.react-datepicker-wrapper {
  width: 75%; }

.labelMargin {
  margin-top: 1.4em;
  font-weight: bold;
  margin-left: 4em; }

.datepickerrangeto {
  margin-top: 1em; }

.datepickerrangefrom {
  margin-left: 1em;
  margin-top: 1em; }

.rdrStaticRanges {
  -webkit-flex-direction: row !important;
          flex-direction: row !important; }

.datestaticrange {
  margin-left: 0em;
  margin-top: 1em; }

.dashboard {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0;
  margin-top: 15px; }
  .dashboard .dashboard-text {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    text-align: right;
    -webkit-align-self: flex-end;
            align-self: flex-end; }
  .dashboard > button.success {
    border-color: #76b900; }
    .dashboard > button.success .badge {
      background-color: #76b900;
      color: #fff; }
  .dashboard > button.success-alt {
    border-color: #4f7c00; }
    .dashboard > button.success-alt .badge {
      background-color: #4f7c00;
      color: #fff; }
  .dashboard > button.cancel-alt {
    border-color: #eccd08; }
    .dashboard > button.cancel-alt .badge {
      background-color: #eccd08;
      color: #fff; }
  .dashboard > button.warn {
    border-color: #f38f1d; }
    .dashboard > button.warn .badge {
      background-color: #f38f1d;
      color: #fff; }
  .dashboard > button.error {
    border-color: #9c0000; }
    .dashboard > button.error .badge {
      background-color: #9c0000;
      color: #fff; }
  .dashboard > button {
    transition: all 0.25s ease-in;
    margin-bottom: 2em;
    display: -webkit-flex;
    display: flex;
    border: 1px solid #c9c9c9;
    border-radius: 3px;
    background-color: #fff;
    padding: 0;
    -webkit-align-items: stretch;
            align-items: stretch;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23); }
    .dashboard > button:hover {
      cursor: pointer;
      background-color: #f2f2f2;
      box-shadow: none; }
    .dashboard > button .badge {
      width: 50px;
      padding: 0.75em 0.2em;
      background-color: #c9c9c9;
      color: #333; }
    .dashboard > button .text {
      padding: 0.75em; }
    .dashboard > button + button {
      margin-left: 1em; }

.loading-full {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-grow: 1;
          flex-grow: 1; }
  .loading-full .loading-text {
    color: #333;
    font-family: "DINWebPro-Light";
    font-size: 2em;
    padding-left: 0.5em; }

.react-datepicker__input-container {
  width: 100%; }
  .react-datepicker__input-container > input {
    width: 100%; }

.react-datepicker__close-icon {
  height: 15px; }
  .react-datepicker__close-icon::after {
    background-color: #c9c9c9; }
  .react-datepicker__close-icon:hover::after {
    background-color: #bcbcbc;
    color: #333; }

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  position: static; }

.ReactTable .rt-th {
  overflow: visible; }

.ReactTable .rt-table .rt-td {
  overflow: hidden; }

.ReactTable .btn {
  box-shadow: none;
  width: 90px;
  font-family: "DINWebPro-Light";
  padding: 0.5em 0; }

.ReactTable .-pagination .-btn:not([disabled]) {
  background-color: #76b900;
  color: #fff; }

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: #83cd00;
  color: #fff; }

.ReactTable .rt-noData {
  display: block;
  position: absolute;
  left: 50%;
  top: 190px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: #000;
  border: 2px solid #76b900;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23); }

.reg-list {
  width: 100%;
  margin-top: 1em; }
  .reg-list .filter-control {
    padding-top: 1em;
    display: -webkit-flex;
    display: flex; }
    .reg-list .filter-control > input,
    .reg-list .filter-control select {
      margin-right: 0.5em;
      width: 100%;
      padding: 0;
      padding: 0.15em; }
  .reg-list .filter-control-stub {
    height: 23px;
    padding-top: 1em; }
  .reg-list > div:nth-child(1) {
    background-color: #76b900;
    color: #fff; }
  .reg-list > div:nth-child(2n + 2) {
    background-color: #e2e2e2; }
  .reg-list > div {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    border: 1px solid #d5d5d5;
    border-top: none; }
    .reg-list > div > div {
      margin: 0.15em;
      word-break: break-all; }
    .reg-list > div > div:nth-child(1) {
      width: 30px; }
    .reg-list > div > div:nth-child(2) {
      width: 90px; }
    .reg-list > div > div:nth-child(3) {
      width: 110px; }
    .reg-list > div > div:nth-child(4) {
      width: 90px; }
    .reg-list > div > div:nth-child(5) {
      width: 150px;
      word-wrap: break-word; }
    .reg-list > div > div:nth-child(6) {
      -webkit-flex-grow: 1;
              flex-grow: 1; }
    .reg-list > div > div:nth-child(7) {
      width: 90px; }
    .reg-list > div > div:nth-child(8) {
      width: 140px; }
      .reg-list > div > div:nth-child(8) > .filter-control {
        -webkit-flex-direction: column;
                flex-direction: column; }
        .reg-list > div > div:nth-child(8) > .filter-control > input[type="date"] {
          width: auto; }
    .reg-list > div > div:nth-child(9) {
      width: 70px;
      margin: 0em;
      text-align: center; }
      .reg-list > div > div:nth-child(9) > a {
        height: 100%;
        width: 100%; }
        .reg-list > div > div:nth-child(9) > a button {
          padding: 0.25em;
          height: 100%;
          width: 100%;
          box-shadow: none;
          background-color: #c9c9c9;
          color: #000;
          border: 1px solid #d0d0d0; }
          .reg-list > div > div:nth-child(9) > a button:hover {
            cursor: pointer;
            background-color: #d5d5d5; }

.content .section-highlight {
  padding-top: 1em;
  padding-bottom: 1em;
  margin-left: -1em;
  margin-right: -1em;
  background-color: #76b900;
  border-bottom: 3px solid #4f7c00;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23); }
  .content .section-highlight > .flex:first-child {
    margin-bottom: 1em; }
  .content .section-highlight label.block {
    color: #fff; }

.content .flexwrapstyle {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.content .flexwrapsize {
  -webkit-flex-basis: 33%;
          flex-basis: 33%; }

.content .details-page-header {
  color: #76b900;
  margin-top: 0; }
  .content .details-page-header > small {
    color: #333;
    font-size: 0.6em;
    padding-left: 2em; }

.content .details-reg-header {
  color: #333;
  margin-top: 0.25em;
  font-size: 2em; }
  .content .details-reg-header > small {
    color: #4f7c00;
    font-size: 0.6em;
    padding-right: 0.5em;
    font-style: italic; }

.content .details-section-header {
  color: #4f7c00;
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  font-size: 1.5em;
  display: inline-block; }

.content label.block {
  width: 150px;
  font-size: 1.2em;
  text-align: right;
  padding-right: 1em;
  margin-bottom: 0.5em; }

.content .sub-status {
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em; }
  .content .sub-status > div:last-child {
    border-bottom: none; }
  .content .sub-status > div:not(.color-neutral) > div:first-child {
    font-weight: 900; }
  .content .sub-status > div {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid #c9c9c9; }
    .content .sub-status > div > div:first-child {
      width: 100px;
      min-width: 100px; }
    .content .sub-status > div > div:nth-child(2) {
      width: 100px;
      min-width: 100px; }
    .content .sub-status > div > div:nth-child(3) {
      -webkit-flex-grow: 1;
              flex-grow: 1;
      word-wrap: break-word;
      min-width: 100px; }

.content .company-details {
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em; }
  .content .company-details label {
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-align-items: center;
            align-items: center;
    height: 2.3em; }
    .content .company-details label input[type="text"] {
      -webkit-flex: none;
              flex: none;
      position: absolute;
      left: 60px;
      border: 1px solid #c9c9c9;
      border-radius: 4px;
      padding: 0.5em 0.75em; }

@media screen and (max-width: 400px) {
  .dashboard {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0; }
    .dashboard > button {
      margin-bottom: 0; }
    .dashboard > button + button {
      margin-left: 0em; }
    .dashboard > button > .text {
      -webkit-flex-grow: 1;
              flex-grow: 1; }
    .dashboard > .dashboard-text {
      -webkit-align-self: center;
              align-self: center;
      margin-top: 2em; }
  header .header-content {
    background-size: cover;
    width: 100vw;
    padding-left: 1em; }
  .content .details-reg-header {
    margin-top: 0; }
    .content .details-reg-header small {
      display: block;
      margin-bottom: 0.5em; }
  .content .details-section-header {
    color: #333;
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 1.5em; }
    .content .details-section-header > small {
      color: #4f7c00;
      font-size: 0.6em;
      padding-right: 0.5em;
      font-style: italic;
      display: block; }
  .content .btn.inline.bg-color-nvgreen {
    margin: -1em 0em 1em 0; } }

.MuiCheckbox-root{
  padding: 1px!important;
  }
.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
}
.DateRangePicker_1 {
  margin-left: 1em;
  min-width: 300px;
}
.DateRangePickerInput_1 {
  min-width: 100%;
}
.selectedBtn {
  background-color: #76b900 !important;
  color: white !important;
}
.defaultBtn {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}
.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  z-index: 1;

  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}

.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.25rem 0.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}

.list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.list-group-item + .list-group-item {
  border-top-width: 0;
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -webkit-flex-direction: row;
          flex-direction: row;
}

.list-group-horizontal .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}

.list-group-horizontal .list-group-item.active {
  margin-top: 0;
}

.list-group-horizontal .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}

.list-group-horizontal .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-sm .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-md .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-lg .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-xl .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:first-child {
  border-top-width: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #004085;
  background-color: #b8daff;
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: #004085;
  background-color: #9fcdff;
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #004085;
  border-color: #004085;
}

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db;
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: #383d41;
  background-color: #c8cbcf;
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #383d41;
  border-color: #383d41;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca;
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: #1b1e21;
  background-color: #b9bbbe;
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #1b1e21;
  border-color: #1b1e21;
}

.card {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.border-success {
  /* border-color: #28a745 !important; */
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group:first-child .list-group-item:first-child {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.card > .list-group:last-child .list-group-item:last-child {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.card-body {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  font-weight: bold;
  text-align: center;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header + .list-group .list-group-item:first-child {
  border-top: 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
}

.card-img,
.card-img-top,
.card-img-bottom {
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-deck {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    -webkit-flex: 1 0;
            flex: 1 0;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}

@media (min-width: 576px) {
  .card-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-flex: 1 0;
            flex: 1 0;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    /* margin-left: 0px;
    border-left: 0; */
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 10px;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    grid-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion > .card {
  overflow: hidden;
}

.pcustpopup-content {
    max-width: 100% !important;
    max-height: 100% !important;
    width: 100% !important;
    margin: 100px !important;
}

    .pcustpopup-content .btn {
        border: none;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
        background-color: #4f7c00;
        transition: all 0.25s ease-in;
        color: #fff;
        padding: 0.75em 1.25em;
        font-size: 1.1em;
        border-radius: 0.2em;
        width:auto;
        font-family:'';
    }
    .pcustpopup-content .selectDiv {
        display: block;
    }
    .pcustpopup-content .state {
        margin-right: 15px !important;
    }
    .pcustpopup-content .selectDiv label {
        margin-right: 1px;
    }
    .pcustpopup-content .selectDiv select {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        height: 30px !important;
        width: 183px !important;
        margin-left: 0.7rem !important;
        border: 1px solid #c9c9c9;
        border-radius: 4px;
    }

.Collapsible {
  background-color: #ffffff; }

.Collapsible__contentInner {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0; }
  .Collapsible__contentInner p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px; }
    .Collapsible__contentInner p:last-child {
      margin-bottom: 0; }

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #767676;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #76b900;
  color: white; }
  .Collapsible__trigger:after {
    font-family: "FontAwesome";
    content: "\F107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: -webkit-transform 300ms;
    transition: transform 300ms;
    transition: transform 300ms, -webkit-transform 300ms; }
  .Collapsible__trigger.is-open:after {
    -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg); }
  .Collapsible__trigger.is-disabled {
    opacity: 0.5;
    background-color: grey; }

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease; }

.CustomTriggerCSS--open {
  background-color: darkslateblue; }

.auditTrailSty {
  max-width: 50%; }

.MuiButton-root {
  text-transform: inherit !important; }

.auditTableTitleN {
  background-color: white !important;
  font-weight: 600 !important;
  font-size: 1.3em !important; }

.cancelRedStyles {
  background-color: #ff0000 !important;
  color: white !important; }

.auditBtn {
  max-width: 100%; }

.auditBtnPad {
  margin-left: 0.15em; }

.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black; }

.cancelRegStyles {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  width: 33%; }

.DescriptionWrap {
  white-space: normal; }

.MuiInputBase-root {
  height: 50px; }

.MuiGrid-root {
  margin-top: 1% !important; }

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #76b900 !important; }

.modal {
  font-size: 12px; }

.popup-overlay {
  z-index: 1500 !important; }

.MuiFormControl-root {
  width: 100%;
  width: "25ch"; }

.popup-content {
  min-width: 60%;
  min-height: 35%;
  max-width: 90% !important;
  max-height: 50%;
  overflow-y: auto;
  margin: 10px;
  width: 80% !important; }

.MuiTableRow-head {
  background-color: #f5f5f5; }

.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px; }

.modal > .content {
  width: 100%;
  padding: 10px 5px; }

.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center; }

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  margin: 7px; }

.customerrormsgfont {
  font-weight: bold; }

.MuiButton-colorInherit {
  color: white !important;
  background-color: #76b900 !important; }

.rowClassName1 {
  background-color: #fff;
  border-radius: 10px !important;
  border-color: red;
  border-width: 16px !important;
  border-style: solid !important; }

.ant-table-tbody {
  padding: 5px !important; }

.ant-table-tbody > tr > td {
  padding: 8px 8px 8px 8px !important; }

.ant-table {
  margin: 20px !important; }

.auditButton {
  margin-right: 10px !important; }

