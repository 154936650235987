// The main container element

.Collapsible {
  background-color: #ffffff;
}

//The content within the collaspable area
.Collapsible__contentInner {
  // padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-top: 0;

  p {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  color: #767676;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #76b900;
  color: white;

  &:after {
    font-family: "FontAwesome";
    content: "\f107";
    position: absolute;
    right: 10px;
    top: 10px;
    display: block;
    transition: transform 300ms;
  }

  &.is-open {
    &:after {
      transform: rotateZ(180deg);
    }
  }

  &.is-disabled {
    opacity: 0.5;
    background-color: grey;
  }
}

.CustomTriggerCSS {
  background-color: lightcoral;
  transition: background-color 200ms ease;
}

.CustomTriggerCSS--open {
  background-color: darkslateblue;
}
.auditTrailSty {
  max-width: 50%;
  // margin-left: 2em;
}
.MuiButton-root {
  text-transform: inherit !important;
}
.auditTableTitleN {
  background-color: white !important;
  font-weight: 600 !important;
  font-size: 1.3em !important;
}
.cancelRedStyles {
  background-color: #ff0000 !important;
  color: white !important;
  // font-size: 1em !important;
}
.auditBtn {
  // color: white !important;
  // background-color: #76b900 !important;
  max-width: 100%;
}
.auditBtnPad {
  margin-left: 0.15em;
}
.Collapsible__custom-sibling {
  padding: 5px;
  font-size: 12px;
  background-color: #cbb700;
  color: black;
}
.cancelRegStyles {
  flex-grow: 1;
  width: 33%;
  // height: 100px;
}
.DescriptionWrap {
  white-space: normal;
}
.MuiInputBase-root {
  height: 50px;
}
.MuiGrid-root {
  margin-top: 1% !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
  color: #76b900 !important;
}
.modal {
  font-size: 12px;
}
.popup-overlay {
  z-index: 1500 !important
  ;
}
.MuiFormControl-root {
  width: 100%;
  width: "25ch";
}
.popup-content {
  min-width: 60%;
  min-height: 35%;
  max-width: 90% !important;
  max-height: 50%;
  overflow-y: auto;
  margin: 10px;
  width: 80% !important;
}

.MuiTableRow-head {
  background-color: #f5f5f5;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
  margin: 7px;
}
.customerrormsgfont {
  font-weight: bold;
}

.MuiButton-colorInherit {
  color: white !important;
  background-color: #76b900 !important;
}
